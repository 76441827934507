<template>
  <input :class="`input-style ${variant}`" type="date" />
</template>
    
    <script>
export default {
  name: 'BaseInput',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: '',
    },
  },
}
</script>
    
    <style scoped>
.input-style {
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  width: 100%;
  border: 1px solid #9e9e9e;
  border-radius: 8px;
}
.input-style::placeholder {
  color: #8d9db5;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}
.input-style:focus {
  outline: none;
}
</style>
    
    
    