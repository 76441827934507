import Cookies from 'js-cookie'

const tokenKey = 'token'
export const setToken = (token) => {
  Cookies.set(tokenKey, token, { expires: 1 / 24 })
}

export const getToken = () => {
  let value = ''
  if (Cookies.get(tokenKey) !== undefined) {
    value = Cookies.get(tokenKey)
  }
  return value
}

export const removeToken = () => {
  return Cookies.remove(tokenKey)
}
