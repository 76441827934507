<template>
  <div class="layout-container">
    <div class="menu-item">
      <Sidebar />
    </div>
    <div class="header-item">
      <Header />
    </div>
    <div class="main-item">
      <main class="bg-gray-100 min-h-[calc(100vh-80px)]">
        <slot />
      </main>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header/HeaderDasboard.vue'
import Sidebar from '@/components/Sidebar'
export default {
  name: 'LayoutDashboard',
  components: {
    Header,
    Sidebar,
  },
}
</script>
<style scoped>
.layout-container {
  display: grid;
  grid-template-columns: 200px auto;
  grid-template-rows: auto auto;
}
.header-item {
  grid-column-start: 2;
  width: 100%;
}
.menu-item {
  grid-column-start: 1;
  grid-row: 1 / span 2;
}
.main-item {
  grid-row-start: 2;
  height: 100%;
}

@media (min-width: 1200px) {
  .layout-container {
    display: grid;
    grid-template-columns: 325px auto;
    grid-template-rows: auto auto;
  }
}
</style>
