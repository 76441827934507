<template>
  <div>
    <div class="grid grid-row-2 px-[24px]">
      <div class="grid grid-cols-2 hidden">
        <div class="mr-3">
          <label>Tanggal Mulai</label>
          <InputDate placeholder="Tanggal" variant="input" />
        </div>
        <div>
          <label>Tanggal Akhir</label>
          <InputDate placeholder="Tanggal" variant="input" />
        </div>
      </div>
      <div class="mb-3">
        <label>Posisi</label>
        <DropdownFilter
          title="Pilih posisi yang Anda inginkan"
          :list-options="getListRoles"
          :is-reset="getReset"
          :value-filter="getValueFilter?.role?.name"
          type-filter="role"
          @submit="setDataFilterHandle"
        />
      </div>
      <div>
        <label>Status</label>
        <div class="z-0">
          <DropdownFilter
            title="Pilih status yang Anda inginkan"
            :list-options="getListStatus"
            :is-reset="getReset"
            :value-filter="getValueFilter?.status?.name"
            type-filter="status"
            @submit="setDataFilterHandle"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputDate from '@/components/Base/InputDate'
import DropdownFilter from '@/components/Base/DropdownFilter'
import { mapGetters } from 'vuex'

export default {
  name: 'PopupFilter',
  components: { InputDate, DropdownFilter },
  data() {
    return {
      filtered: {},
    }
  },
  computed: {
    ...mapGetters(['getReset', 'getListRoles', 'getListStatus', 'getFilter']),
    getValueFilter() {
      return this.setValueFilter()
    },
  },
  mounted() {
    this.$store.dispatch('getAllRolesHandle')
    this.$store.dispatch('getAllStatusHandle')
  },
  methods: {
    setDataFilterHandle(value) {
      this.filtered[value.type] = value.option
      this.$emit('setDataFilter', this.filtered)
    },
    setValueFilter() {
      let result = ''

      if (this.getReset) {
        this.filtered = {}
      } else {
        result = JSON.stringify(this.getFilter)
        this.filtered = JSON.parse(result)
      }
      return this.filtered
    },
  },
}
</script>

<style scoped>
label {
  font-family: 'Lato', sans-serif;
  font-size: 15px;
}
.input {
  border: 1px solid #9e9e9e;
  border-radius: 8px;
  height: 38px;
  margin-top: 6px;
  padding-left: 5px;
  padding-right: 5px;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  color: #424242;
}
</style>
