import axios from 'axios'

const baseURL = process.env.VUE_APP_BASE_URL

const apiRequest = (token) => {
  const headers = {}
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }
  return axios.create({
    baseURL: baseURL,
    headers,
  })
}

export default apiRequest
