<template>
  <transition
    name="fade"
    enter-active-class="transition ease-in-out duration-300"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition ease-in-out duration-200"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div v-show="showPopup">
      <div
        class="
          fixed
          inset-0
          bg-gray-500 bg-opacity-50
          transition-opacity
          flex
          justify-center
          items-center
          w-full
        "
        @click.self="close"
      >
        <div class="bg-white rounded w-[500px] h-[350px]">
          <!-- HEADER -->
          <div class="pt-5">
            <div class="flex justify-between px-5">
              <h1
                class="font-[Roboto] text-[22px] font-semibold text-[#022B55]"
              >
                Filter
              </h1>
              <SecondaryButton variant="px-2 py-2 rounded-lg" @click="setReset">
                Reset Filter
              </SecondaryButton>
            </div>
          </div>
          <!-- BODY -->
          <div class="mt-10">
            <FormInput @setDataFilter="setDataFilter" />
          </div>
          <!-- FOOTER -->
          <div class="px-[24px] flex justify-end mt-10">
            <SecondaryButton variant="h-[38px] w-[69px] mr-3" @click="close">
              Tutup
            </SecondaryButton>
            <PrimaryButton
              variant="w-[126px] h-[38px]"
              @click="submitFilterHandle"
            >
              Terapkan Filter
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
  
<script>
import SecondaryButton from '@/components/Base/Button/SecondaryButton.vue'
import FormInput from '@/components/PopupFilter/FormInput.vue'
import PrimaryButton from '@/components/Base/Button/PrimaryButton.vue'

export default {
  name: 'PopupFilter',
  components: { SecondaryButton, FormInput, PrimaryButton },
  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataFilter: {},
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    setDataFilter(value) {
      const result = JSON.stringify(value)
      this.dataFilter = JSON.parse(result)
    },
    submitFilterHandle() {
      this.$emit('filterHandle', this.dataFilter)
      this.close()
    },
    setReset() {
      this.dataFilter = {}
      this.$store.commit('setReset', true)
      this.$store.commit('setFilter', {})
      this.$store.dispatch('filterHandle')
      this.close()
    },
  },
}
</script>
  
<style>
.modal-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>