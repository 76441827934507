<template>
  <layout-dashboard>
    <Modal :show="getIsLoading"></Modal>
    <div class="container mx-auto flex pt-[102px] px-[24px] pb-10">
      <ListLayout />
    </div>
  </layout-dashboard>
</template>

<script>
import ListLayout from '@/components/ListLayout'
import Modal from '@/components/Base/Modal'

export default {
  name: 'KandidatListPage',
  components: {
    ListLayout,
    Modal,
  },
  computed: {
    getIsLoading() {
      return this.$store.getters.getLoading
    },
  },
  mounted() {
    this.$store.commit('setLoading', true)
  },
}
</script>
