<template>
  <div
    class="
      relative
      bg-gradient-to-tr
      from-[#022B55]
      via-[#022B55]
      to-[#1A4373]
      h-screen
      w-full
    "
  >
    <div class="mt-[40px] mx-[40px] flex justify-between">
      <img :src="require('@/assets/images/Pattern.svg')" />
      <div class="flex justify-end hidden">
        <router-link
          to="#"
          class="font-[Roboto] text-white font-bold text-[16px]"
          >Ke Beranda
        </router-link>
        <div class="ml-2">
          <img
            :src="require('@/assets/icons/home-icon.svg')"
            width="17"
            height="17"
          />
        </div>
      </div>
    </div>
    <div class="flex justify-start items-center h-[calc(100vh-400px)]">
      <div class="lg:mx-[40px] xl:mx-[90px] z-10">
        <h2 class="font-[Lato] text-[30px] text-white uppercase font-bold">
          Digiteam
        </h2>
        <h1
          class="
            font-[Roboto]
            text-white
            lg:text-[50px]
            2xl:text-[60px]
            font-bold
          "
        >
          Talent Recruitment Platform
        </h1>
      </div>
    </div>
    <div class="absolute bottom-0 right-10">
      <img :src="require('@/assets/images/header-watermark.svg')" />
    </div>
  </div>
</template>
