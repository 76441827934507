<template>
  <div class="layout-container">
    <!-- <div class="bg-blue-600 h-screen"></div>
      <div class="bg-green-600 h-screen"></div> -->
    <div class="z-10">
      <HeaderLogin />
      <slot />
    </div>
    <SideLayout />
  </div>
</template>
<script>
import HeaderLogin from '@/components/Header/HeaderLogin.vue'
import SideLayout from '@/components/Login/SideLayout.vue'

export default {
  name: 'LayoutLogin',
  components: {
    HeaderLogin,
    SideLayout,
  },
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
.layout-container {
  display: grid;
  grid-template-columns: 50% 50%;
}
</style>
