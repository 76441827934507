<template>
  <transition
    name="fade"
    enter-active-class="transition ease-in-out duration-300"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition ease-in-out duration-200"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div v-show="show">
      <div
        class="
          fixed
          inset-0
          bg-gray-500 bg-opacity-50
          transition-opacity
          flex
          justify-center
          items-center
          w-full
        "
        @click.self="close"
      >
        <div class="bg-white rounded w-[450px] h-[265px]">
          <!-- HEADER -->
          <div class="pt-10 modal-header">
            <h1 class="title">Tunggu Sebentar</h1>
          </div>
          <div>
            <div class="flex justify-center items-center h-[160px]">
              <div
                class="
                  animate-spin
                  h-[50px]
                  w-[50px]
                  border border-4
                  rounded-full
                  border-t-transparent border-green-600
                "
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style>
.modal-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.modal-header {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Inter', serif;
}

.modal-header .error {
  color: red;
}

.modal-header .title {
  color: #069550;
}

.modal-body {
  padding: 5px 20px 20px;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
}
.modal-footer {
  background-color: #f5f5f5;
  padding: 16px;
  border-radius: 8px;
  text-align: right;
}

.button-cancel {
  width: 87px;
  padding: 10px 16px 10px 16px;
  height: 38px;
  background-color: transparent;
  border: 1px solid #069550;
  border-radius: 8px;
  color: #069550;
  font-size: 14px;
  font-weight: 700;
}

.button-submit {
  height: 38px;
  background-color: #069550;
  padding: 10px 16px 10px 16px;
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 14px;
  font-weight: 700;
  margin-left: 20px;
}

.item-icon {
  color: #069550;
}
</style>