<template>
  <div class="relative">
    <SecondaryButton
      variant="h-[30px] w-full xl:w-[85px] py-[3px] px-[5px] xl:px-[16px] text-[14px] w-[500]"
      @click="showDropdownContent"
    >
      <template #icon-right>
        <div class="flex justify-center items-center">
          Aksi
          <div class="ml-2">
            <img
              :src="require('@/assets/icons/arrow-down.svg')"
              width="12"
              height="14"
            />
          </div>
        </div>
      </template>
    </SecondaryButton>
    <PopupAction
      :show-popup-action="isShowDropdownContent && isOpenPopup == listIndex"
    />
  </div>
</template>
<script>
import SecondaryButton from '@/components/Base/Button/SecondaryButton.vue'
import PopupAction from '@/components/Base/PopupAction'

export default {
  name: 'BaseDropdownMenu',
  components: {
    SecondaryButton,
    PopupAction,
  },
  props: {
    isOpenPopup: {
      type: Number,
      default: null,
    },
    listIndex: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isShowDropdownContent: false,
    }
  },
  methods: {
    showDropdownContent() {
      this.isShowDropdownContent = !this.isShowDropdownContent
    },
  },
}
</script>
