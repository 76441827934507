<template>
  <table class="w-full mb-5 h-full round-b-lg">
    <thead
      class="
        bg-green-700
        text-white
        font-[Roboto]
        h-[42px]
        lg:text-[12px]
        xl:text-[14px]
      "
    >
      <tr>
        <th
          v-for="(title, index) in titleTable"
          :key="index"
          :class="`border-r border-white px-3 w-[300px] ${
            index == 0
              ? 'rounded-tl-lg'
              : index == titleTable.length - 1
              ? 'rounded-tr-lg'
              : ''
          }`"
        >
          <div
            v-if="index < titleTable.length - 1"
            class="flex justify-between items-center"
          >
            {{ title }}
            <div>
              <ArrowDownIcon class="w-[16px] h-[16px] text-white ml-3 hidden" />
            </div>
          </div>
          <div v-else class="text-center">{{ title }}</div>
        </th>
      </tr>
    </thead>
    <tbody>
      <slot />
    </tbody>
    <tfoot
      class="
        border border-[#eeeeee]
        rounded-lg
        bg-[#FAFAFAFA]
        border-t-2 border-t-green-600
      "
    >
      <tr class="rounded-2xl">
        <td :colspan="(titleTable.length - 1) / 2">
          <div class="flex items-center">
            <p class="mr-3 pl-[16px]">Tampilkan</p>
            <select :value="currentPageSize" @change="changePageSizeHandle">
              <option
                v-for="pageSize in pagePerSize"
                :key="pageSize"
                :value="pageSize"
              >
                {{ pageSize }}
              </option>
            </select>
            <p class="ml-3 mr-3">Item</p>
            <div
              class="
                w-[100px]
                border-x border-[#EEEEEE]
                h-[44px]
                flex
                justify-center
                items-center
              "
            >
              <p>
                dari total <b>{{ totalData }}</b>
              </p>
            </div>
          </div>
        </td>
        <td :colspan="titleTable.length + 1 - (titleTable.length - 1) / 2">
          <div class="flex justify-end items-center">
            <p class="mr-3">Halaman</p>
            <select :value="currentPage" @change="changePageHandle">
              <option v-for="index in page" :key="index" :value="index">
                {{ index }}
              </option>
            </select>
            <p class="mx-3">dari {{ page }}</p>
            <button
              v-if="currentPage > 1"
              class="arrow-box cursor-pointer"
              @click="$emit('page', currentPage - 1)"
            >
              <ChevronLeftIcon class="icon" />
            </button>
            <button
              v-else-if="currentPage == 1"
              disabled
              class="arrow-box cursor-pointer"
            >
              <ChevronLeftIcon class="icon-disabled" />
            </button>
            <button
              v-if="currentPage !== page"
              class="arrow-box cursor-pointer"
              @click="$emit('page', currentPage + 1)"
            >
              <ChevronRightIcon class="icon" />
            </button>
            <button
              v-else-if="currentPage == page"
              class="arrow-box cursor-pointer"
              disabled
              @click="$emit('page', currentPage + 1)"
            >
              <ChevronRightIcon class="icon-disabled" />
            </button>
          </div>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import {
  ArrowDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/vue/24/outline'

export default {
  name: 'BaseTable',
  components: {
    ArrowDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  props: {
    titleTable: {
      type: Array,
      default: new Array(),
    },
    totalData: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    currentPageSize: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      pagePerSize: [5, 10, 20],
    }
  },
  methods: {
    changePageHandle(event) {
      this.$emit('page', event.target.value)
    },
    changePageSizeHandle(event) {
      this.$emit('pageSize', event.target.value)
    },
  },
}
</script>

<style scoped>
select {
  height: 44px;
  width: 67px;
  appearance: none;
  background-image: url('@/assets/icons/arrow-down.svg');
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  font-size: 12px;
  padding-left: 10px;
}
select:focus {
  outline: none;
}
p {
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #424242;
}
.arrow-box {
  width: 44px;
  height: 44px;
  border-left: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon {
  width: 20px;
  height: 20px;
  color: #008444;
}

.icon-disabled {
  width: 20px;
  height: 20px;
  color: #bdbdbd;
}

@media (min-width: 1200px) {
  p {
    font-size: 14px;
  }
}
</style>
