<template>
  <div>
    <button
      class="
        bg-transparent
        text-green-600
        border border-[#069550]
        rounded
        text-[14px]
        font-[Lato] font-bold
      "
      :class="`${variant}`"
    >
      <slot name="icon-left" />
      <slot name="default" />
      <slot name="icon-right" />
    </button>
  </div>
</template>
<script>
export default {
  name: 'SecondaryButton',
  props: {
    name: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'w-[81px] h-[38px]',
    },
  },
  data() {
    return {}
  },
}
</script>
  
  
  
  
  