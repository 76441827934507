<template>
  <div
    class="
      max-w-[219px]
      h-[34px]
      bg-green-700
      text-white
      rounded-full
      flex
      justify-between
      items-center
      px-3
      font-[Lato]
      text-[13px]
      mr-3
    "
  >
    <p class="truncate">{{ filterResult }}</p>
    <div
      class="
        w-[14px]
        h-[14px]
        bg-[#D7D7D7]
        rounded-full
        flex
        items-center
        justify-center
        ml-3
      "
      @click="closeHandle"
    >
      <XMarkIcon class="w-[12px] h-[12px] text-green-700" />
    </div>
  </div>
</template>
<script>
import { XMarkIcon } from '@heroicons/vue/24/outline'
export default {
  name: 'LabelFilter',
  components: {
    XMarkIcon,
  },
  props: {
    filterResult: {
      type: String,
      default: '',
    },
    typeFilter: {
      type: String,
      default: '',
    },
  },
  methods: {
    closeHandle() {
      this.$emit('closeLabel', this.typeFilter)
    },
  },
}
</script>
