import axios from 'axios'
import { createStore } from 'vuex'
import { removeToken, setToken, getToken } from '@/helpers/cookie'
import apiRequest from '@/helpers/api-request'

const store = createStore({
  state() {
    return {
      user: {},
      errMessage: '',
      isLoading: false,
      isError: false,
      token: '',
      menuActive: '',
      menus: [
        { id: 1, name: 'List Kandidat', icons: 'list-user', link: '/kandidat' },
      ],
      listKandidat: [],
      pagination: {},
      currentPage: 1,
      pageSize: 5,
      listRoles: [],
      listStatus: [
        { id: '01', name: 'New' },
        { id: '02', name: 'Personal Data' },
      ],
      filter: {},
      search: '',
      isReset: false,
      isShowLabelFilter: false,
    }
  },
  getters: {
    getUser: (state) => {
      return state.user
    },
    getErrorMessage: (state) => {
      return state.errMessage
    },
    getIsError: (state) => {
      return state.isError
    },
    getLoading: (state) => {
      return state.isLoading
    },
    getToken: (state) => {
      return state.token
    },
    getMenuActive: (state) => {
      return state.menuActive
    },
    getMenus: (state) => {
      return state.menus
    },
    getListKandidat: (state) => {
      return state.listKandidat
    },
    getPagination: (state) => {
      return state.pagination
    },
    getCurrentPage: (state) => {
      return state.currentPage
    },
    getPageSize: (state) => {
      return state.pageSize
    },
    getListRoles: (state) => {
      return state.listRoles
    },
    getListStatus: (state) => {
      return state.listStatus
    },
    getFilter: (state) => {
      return state.filter
    },
    getSearch: (state) => {
      return state.search
    },
    getReset: (state) => {
      return state.isReset
    },
  },
  actions: {
    loginHandle: ({ commit }, user) => {
      const baseURL = process.env.VUE_APP_BASE_URL
      const url = `${baseURL}/login`
      return new Promise((resolve, reject) => {
        axios
          .post(url, user)
          .then((response) => {
            const { token } = response.data
            setToken(token)
            commit('setToken', token)
            commit('setIsError', false)
            resolve()
          })
          .catch((error) => {
            const { message } = error.response.data
            commit('setErrorMessage', message)
            commit('setIsError', true)
            reject()
          })
      })
    },
    closeAlert({ commit }, payload) {
      commit('setIsError', payload)
    },
    logoutHandle: async ({ commit }) => {
      return new Promise((resolve) => {
        commit('setToken', '')
        removeToken()
        resolve()
      })
    },
    getUserLoginHandle: async ({ commit }, token) => {
      const baseURL = process.env.VUE_APP_BASE_URL
      const url = `${baseURL}/getme`
      await axios
        .get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          commit('setUser', response.data)
          commit('setIsError', false)
        })
        .catch(() => {
          commit('setIsError', true)
        })
    },
    getAllKandidatHandle: async ({ commit, state }) => {
      commit('setLoading', true)
      await apiRequest(getToken())
        .get(
          `/applications?page=${state.currentPage}&page_size=${state.pageSize}`
        )
        .then((response) => {
          commit('setPagination', response.data)
          commit('setListKandidat', response.data.data)
          commit('setIsError', false)
        })
        .catch(() => {
          commit('setIsError', true)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    },
    getAllRolesHandle: async ({ commit }) => {
      await apiRequest(getToken())
        .get(`/roles`)
        .then((response) => {
          commit('setListRole', response.data)
          commit('setIsError', false)
        })
        .catch(() => {
          commit('setIsError', true)
        })
    },
    getAllStatusHandle: ({ state }) => {
      return state.listStatus
    },
    filterHandle: async ({ commit, state }, dataFilter = {}) => {
      commit('setLoading', true)
      const filterdCategory = ['role', 'status']
      let urlFiltered = ''
      if (state.search !== '') {
        urlFiltered += `&full_name=${state.search}`
      }
      filterdCategory.map((item) => {
        if (Object.keys(dataFilter).length > 0) {
          if (item == 'role' && dataFilter[item] !== undefined) {
            urlFiltered += `&role_id=${dataFilter[item].id}`
          }
          if (item == 'status' && dataFilter[item] !== undefined) {
            urlFiltered += `&status_code=${dataFilter[item].id}`
          }
        }
      })
      await apiRequest(getToken())
        .get(
          `/applications?page=${state.currentPage}&page_size=${state.pageSize}${urlFiltered}`
        )
        .then((response) => {
          commit('setFilter', dataFilter)
          commit('setPagination', response.data)
          commit('setListKandidat', response.data.data)
        })
        .catch(() => {
          commit('setIsError', true)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    },
    setResetHandle: ({ commit }, isReset) => {
      commit('setReset', isReset)
    },
  },
  mutations: {
    setUser: (state, user) => {
      state.user = user
    },
    setLoading: (state, loading) => {
      state.isLoading = loading
    },
    setErrorMessage: (state, error) => {
      state.errMessage = error
    },
    setIsError: (state, error) => {
      state.isError = error
    },
    setToken: (state, token) => {
      state.token = token
    },
    setMenuActive: (state, menuActive) => {
      state.menuActive = menuActive
    },
    setListKandidat: (state, listKandidat) => {
      state.listKandidat = listKandidat
    },
    setPagination: (state, pagination) => {
      state.pagination = pagination
    },
    setPage: (state, page) => {
      state.currentPage = page
    },
    setPageSize: (state, pageSize) => {
      state.pageSize = pageSize
    },
    setListRole: (state, listRole) => {
      state.listRoles = listRole
    },
    setFilter: (state, filter) => {
      state.filter = filter
    },
    setSearch: (state, search) => {
      state.search = search
    },
    setReset: (state, isReset) => {
      state.isReset = isReset
    },
  },
})

export default store
