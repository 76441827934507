<template>
  <LayoutLogin>
    <LoginForm />
  </LayoutLogin>
</template>

<script>
import LoginForm from '@/components/Login/LoginForm.vue'
import LayoutLogin from '@/layouts/LayoutLogin.vue'
export default {
  name: 'LoginPage',
  components: {
    LoginForm,
    LayoutLogin,
  },
}
</script>

