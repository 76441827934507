<template>
  <div>
    <div class="mb-3">
      <label class="text-[15px] text-[#424242] font-[Lato]">{{ label }}</label>
    </div>
    <div
      v-if="typeInput == 'text'"
      :class="`${errorMessage !== '' ? 'error' : 'not-error'}`"
    >
      <input v-model="value" :type="typeInput" :placeholder="placeholder" />
    </div>
    <div
      v-else-if="typeInput == 'password'"
      :class="`${errorMessage !== '' ? 'error' : 'not-error'}`"
    >
      <div class="flex justify-between items-center">
        <input
          id="input"
          v-model="value"
          :type="typeInput"
          :placeholder="placeholder"
        />
        <div v-if="!isShowPassword">
          <EyeIcon class="icon-eyes" @click="showPassword" />
        </div>
        <div v-else>
          <EyeSlashIcon class="icon-eyes" @click="showPassword" />
        </div>
      </div>
    </div>
    <div
      v-show="errorMessage !== ''"
      class="text-[12px] text-red-600 mt-3 font-[Lato]"
      v-html="errorMessage"
    />
  </div>
</template>

<script>
import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/solid'
export default {
  name: 'LoginInput',
  components: {
    EyeIcon,
    EyeSlashIcon,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    typeInput: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    isError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: '',
      isShowPassword: false,
    }
  },
  watch: {
    value(newValue) {
      this.value = newValue
      this.$emit('update:modelValue', newValue)
    },
  },
  methods: {
    showPassword() {
      let typeInput = document.getElementById('input')
      this.isShowPassword = false
      if (typeInput.type === 'password') {
        this.isShowPassword = true
        typeInput.type = 'text'
      } else {
        typeInput.type = 'password'
      }
    },
  },
}
</script>

<style>
.not-error {
  background-color: #fdfdfd;
  height: 38px;
  border: 1px solid #9e9e9e;
  border-radius: 8px;
  width: 100%;
  padding: 8px;
}
.not-error input[type='text'],
.not-error input[type='password'] {
  color: #424242;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  width: 100%;
}
.not-error input[type='text']:focus,
.not-error input[type='password']:focus {
  outline: none;
}

input[type='text']::placeholder,
input[type='password']::placeholder {
  font-size: 14px;
  font-family: 'Lato', sans-serif;
}
.error {
  font-size: 14px;
  color: red;
  margin-top: 5px;
  font-family: 'Lato', sans-serif;
  border: 1px solid red;
  border-radius: 8px;
  width: 100%;
  padding: 8px;
  height: 38px;
  background-color: #fff9fa;
}

.error input[type='text'],
.error input[type='password'] {
  background-color: transparent;
  width: 100%;
  color: #424242;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
}

.error input[type='text']:focus,
.error input[type='password']:focus {
  outline: none;
}

.icon-eyes {
  color: #424242;
  width: 14px;
  height: 14px;
}
</style>
