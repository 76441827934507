<template>
  <div
    id="alert"
    class="
      w-full
      bg-[#FFF9E1]
      border border-[#FF9500]
      h-full
      flex
      my-6
      px-3
      py-3
      rounded
    "
  >
    <ExclamationTriangleIcon class="text-[#FF9500] h-[16px] w-[16px]" />
    <p class="font-[Lato] text-[14px] text-left w-full ml-3">
      {{ message }}
    </p>
    <div class="flex justify-end">
      <XMarkIcon
        class="text-[#FF9500] h-[16px] w-[16px] text-bold"
        @click="closeHandle"
      />
    </div>
  </div>
</template>
<script>
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/solid'

export default {
  name: 'BaseAlert',
  components: {
    ExclamationTriangleIcon,
    XMarkIcon,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
  },
  computed: {},
  methods: {
    closeHandle() {
      this.$emit('close')
    },
  },
}
</script>
