import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import router from '@/router'
import store from '@/store'
import '@/assets/scss/main.scss'
import LayoutDashboard from '@/layouts/LayoutDashboard.vue'
import './router/permission.js'

const app = createApp(App)
app.use(router)
app.use(store)
app.component('LayoutDashboard', LayoutDashboard)
app.mount('#app')
