<template>
  <div
    class="
      bg-white
      min-h-[524px]
      w-full
      border-2 border-green-600
      rounded
      pt-[24px]
      px-[28px]
    "
  >
    <div class="flex items-center">
      <SearchInput v-model="search" placeholder="Cari Kandidat" />
      <SecondaryButton
        variant="mx-3 h-[38px] w-[98px]"
        @click="showPopupFilter"
      >
        <template #icon-left>
          <div class="flex justify-center items-center">
            <FunnelIcon class="w-[16px] h-[16px] mr-2" />
            Filter
          </div>
        </template>
      </SecondaryButton>
      <LabelFilter
        v-for="filter in getFilterHandle"
        :key="filter"
        :filterResult="filter.name"
        :type-filter="filter.type"
        @closeLabel="closeLabelHandle"
      />
    </div>
    <div class="mt-5">
      <Table
        :title-table="tableTitle"
        :total-data="getPagination.total"
        :page="getPagination.last_page"
        :current-page="getPagination.current_page"
        :current-page-size="getPagination.per_page"
        @page="pageHandle"
        @pageSize="pageSizeHandle"
      >
        <tr
          v-for="(dataKandidat, index) in getListKandidat"
          :key="dataKandidat.id"
        >
          <td>
            {{ dataKandidat.full_name }}
          </td>
          <td>
            {{ dataKandidat.role_name }}
          </td>
          <td>
            <p class="break-all">{{ dataKandidat.email }}</p>
          </td>
          <td>
            <p>{{ getDateFormat(dataKandidat.created_at) }}</p>
          </td>
          <td>
            <p>{{ dataKandidat.status_name }}</p>
          </td>
          <td class="text-center">
            <DropdownMenu
              :is-open-popup="showActionIndex"
              :list-index="index"
              @click="setIndexAction(index)"
            />
          </td>
        </tr>
        <tr v-show="getListKandidat.length < 1" class="h-[400px] text-center">
          <td v-if="isFiltered == false" :colspan="tableTitle.length">
            Data Tidak Tersedia
          </td>
          <td v-else :colspan="tableTitle.length">Data Tidak Ditemukan</td>
        </tr>
      </Table>

      <PopupFilter
        :show-popup="isShowPopupFilter"
        @filterHandle="submitFilterHandle"
        @close="closePopupFilter"
      />

      <Modal :show="getLoading" />
    </div>
  </div>
</template>
<script>
import SecondaryButton from '@/components/Base/Button/SecondaryButton.vue'
import Table from '@/components/Table'
import PopupFilter from '@/components/PopupFilter'
import SearchInput from '@/components/Base/SearchInput'
import DropdownMenu from '@/components/Base/DropdownMenu/index.vue'
import { FunnelIcon } from '@heroicons/vue/24/outline'
import { mapGetters } from 'vuex'
import LabelFilter from '@/components/LabelFilter'
import Modal from '@/components/Base/Modal'

export default {
  name: 'TableLayout',
  components: {
    Table,
    SecondaryButton,
    PopupFilter,
    SearchInput,
    DropdownMenu,
    FunnelIcon,
    LabelFilter,
    Modal,
  },
  data() {
    return {
      isShowPopupFilter: false,
      isShowPopupAction: false,
      showActionIndex: -1,
      tableTitle: [
        'Nama Kandidat',
        'Role/Posisi',
        'Email',
        'Tanggal Daftar',
        'Status',
        'Aksi',
      ],
      search: '',
      isFiltered: false,
      isLoading: false,
      dataFilter: {},
    }
  },

  computed: {
    ...mapGetters([
      'getListKandidat',
      'getPagination',
      'getListRoles',
      'getLoading',
      'getShowLabelFilter',
      'getReset',
    ]),
    getFilterHandle() {
      let result = []
      if (Object.keys(this.$store.getters.getFilter).length > 0) {
        result = Object.keys(this.$store.getters.getFilter).map((item) => {
          return {
            name: this.$store.getters.getFilter[item].name,
            type: item, // role, status
          }
        })
      }
      return result
    },
  },

  watch: {
    search() {
      this.$store.commit('setPage', 1)
      this.$store.commit('setPageSize', 5)
      this.$store.commit('setSearch', this.search)
      this.$store.dispatch('filterHandle', this.dataFilter)
      this.isFiltered = true
    },
  },
  mounted() {
    this.$store.dispatch('getAllKandidatHandle')
    this.$store.commit('setPage', 1)
    this.$store.commit('setPageSize', 5)
  },
  updated() {
    if (this.getReset) {
      // TODO : if want to reset search
      // this.search = ''
      this.dataFilter = {}
    }
  },
  methods: {
    showPopupFilter() {
      this.isShowPopupFilter = true
      this.isFiltered = true
      if (this.getReset) {
        this.$store.commit('setReset', false)
      }
    },
    pageHandle(value) {
      this.$store.commit('setPage', value)
      this.$store.dispatch('filterHandle', this.dataFilter)
    },
    pageSizeHandle(value) {
      this.$store.commit('setPageSize', value)
      this.$store.commit('setPage', 1)
      this.$store.dispatch('filterHandle', this.dataFilter)
    },
    closeLabelHandle(value) {
      delete this.$store.state.filter[value]
      const dataFilter = this.$store.state.filter
      this.$store.commit('setFilter', this.$store.state.filter)
      this.$store.dispatch('filterHandle', dataFilter)
    },
    getDateFormat(date) {
      const dateParse = new Date(date)
      const result = dateParse.toLocaleDateString('id', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      })
      return result
    },
    submitFilterHandle(value) {
      this.$store.commit('setPage', 1)
      this.$store.commit('setPageSize', 5)
      this.dataFilter = value
      this.$store.dispatch('filterHandle', this.dataFilter)
    },
    closePopupFilter() {
      this.isShowPopupFilter = false
    },
    setIndexAction(index) {
      this.showActionIndex = index
    },
  },
}
</script>

<style scoped>
tr {
  font-size: 12px;
  font-family: 'Lato', sans-serif;
}
tr:nth-child(even) {
  background-color: #fafafa;
}
td {
  border: 1px solid #eeeeee;
  padding: 10px 20px;
}

@media (min-width: 1200px) {
  tr {
    font-size: 14px;
    font-family: 'Lato', sans-serif;
  }
}
</style>