<template>
  <header class="w-full bg-white h-[80px] drop-shadow-xl">
    <div class="flex justify-between items-center px-[40px] h-[80px]">
      <h1 class="font-[Roboto] font-[600] text-[#616161] text-[22px]">
        {{ getMenuActive }}
      </h1>
      <div class="dropdown">
        <button @click="showDropdownContent">
          <div class="flex justify-end items-center h-[80px]">
            <div class="border border-2 border-green-600 rounded-full p-[4px]">
              <img
                :src="require('@/assets/logo/logo-jds-home.svg')"
                alt="profile"
                height="30"
                width="30"
              />
            </div>
            <h1 class="font-[Roboto] text-[16px] text-gray-700 font-[500] ml-3">
              {{ getUser.name }}
            </h1>
            <ChevronDownIcon
              :class="`icon ${
                isShowDropdownContent ? 'rotate-180' : 'rotate-0'
              }`"
            />
          </div>
        </button>
        <div v-show="isShowDropdownContent" class="dropdown-content">
          <div class="flex mr-[11px] cursor-pointer" @click="logoutHandle">
            <img :src="require('@/assets/icons/logout.svg')" />
            <p class="font-[Lato] ml-3 text-[14px]">Keluar</p>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
    
<script>
import { ChevronDownIcon } from '@heroicons/vue/24/solid'
import { mapGetters } from 'vuex'

export default {
  name: 'HeaderDashboard',
  components: {
    ChevronDownIcon,
  },
  data() {
    return {
      isShowDropdownContent: false,
    }
  },
  computed: {
    ...mapGetters(['getUser', 'getMenuActive']),
  },
  methods: {
    showDropdownContent() {
      this.isShowDropdownContent = !this.isShowDropdownContent
    },
    logoutHandle() {
      this.$store.dispatch('logoutHandle')
      this.$router.push('/login')
    },
  },
}
</script>
<style scope>
.dropdown {
  position: relative;
  display: inline-block;
  height: 80px;
}
.dropdown-content {
  position: absolute;
  bottom: 0px;
  top: 80px;
  background-color: white;
  min-width: 100%;
  height: fit-content;
  padding: 16px;
  z-index: 1;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.icon {
  height: 20px;
  width: 18px;
  color: #069550;
  margin-left: 10px;
}
</style>