import LoginPage from '@/views/Login.vue'
import HomePage from '@/views/Home.vue'
import KandidatListPage from '@/views/Kandidat/List.vue'

import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    name: 'login',
    path: '/login',
    component: LoginPage,
  },
  {
    name: 'homepage',
    path: '/',
    component: HomePage,
  },
  {
    path: '/kandidat',
    name: 'List Kandidat',
    component: KandidatListPage,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active',
})

export default router
