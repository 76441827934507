<template>
  <div
    v-show="showPopupAction"
    class="absolute bg-white rounded p-[16px] w-fit h-fit z-10 drop-shadow-lg"
  >
    <div
      v-for="(dataAction, index) in listActions"
      :key="index"
      class="font-[Lato] text-[14px] text-left"
      @click="dataAction.action"
    >
      <div class="p-2">{{ dataAction.name }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BasePopupAction',
  props: {
    showPopupAction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      listActions: [
        {
          name: 'Lihat Detail',
          action: '',
        },
        {
          name: 'Kirim Ulang Email',
          action: '',
        },
      ],
    }
  },
}
</script>
