<template>
  <nav class="bg-[#022B55] h-full shadow-md shadow-gray-500/50">
    <div class="pt-[40px] lg:px-[10px] xl:px-[24px]">
      <div class="flex">
        <div>
          <img
            :src="require('@/assets/logo/logo-jds-home.svg')"
            alt="logo-jds"
            height="60"
            width="64"
          />
        </div>
        <div class="ml-3">
          <h2 class="font-[Lato] font-bold text-[12px] text-white uppercase">
            Digiteam
          </h2>
          <h1 class="font-[Roboto] font-bold text-white text-[18px]">
            Talent Recruitment Platform
          </h1>
        </div>
      </div>
      <div class="mt-[82px]">
        <div v-for="menu in getMenus" :key="menu.id">
          <div
            class="mb-3 p-[17px] hover:bg-[#1A4373] rounded"
            :class="`${
              getMenuActive == menu.name
                ? 'active:mb-5 bg-[#1A4373] rounded'
                : ''
            }`"
          >
            <div class="w-full cursor-pointer" @click="directToPage(menu)">
              <div class="flex justify-between items-center">
                <div class="flex">
                  <img
                    :src="require(`@/assets/icons/${menu.icons}.svg`)"
                    height="18"
                    width="18"
                  />
                  <div
                    class="text-[14px] text-white font-bold font-[Lato] ml-3"
                  >
                    {{ menu.name }}
                  </div>
                </div>
                <ChevronRightIcon
                  class="text-white w-[20px] h-[20px] font-bold mr-[18px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { ChevronRightIcon } from '@heroicons/vue/24/solid'
import { mapGetters } from 'vuex'

export default {
  name: 'SidebarLayout',
  components: {
    ChevronRightIcon,
  },
  computed: {
    ...mapGetters(['getMenus', 'getMenuActive']),
  },
  methods: {
    directToPage(menu) {
      this.$router.push(menu.link)
      this.$store.commit('setMenuActive', menu.name)
    },
  },
}
</script>

<style scoped>
.sidebar-menu {
  width: 200px;
}
.sidebar-menu .item {
  font-size: 14px;
  color: white;
  font-family: 'Lato', serif;
  font-weight: 700;
}

.sidebar-menu:hover .item {
  background-color: #1a4373;
}

a.active {
  background-color: #bbbb;
}
.button-logout {
}
</style>