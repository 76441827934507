<template>
  <div class="h-[calc(100vh-80px)] flex justify-center items-center px-10">
    <div class="container mx-auto w-[516px]">
      <h1 class="font-[Roboto] text-[32px] font-bold mb-7 text-[#022B55]">
        Login
      </h1>
      <div v-show="getIsError">
        <Alert :message="getErrorMessage" @close="close" />
      </div>
      <div class="mb-5">
        <Input
          v-model="username"
          label="Username"
          placeholder="Masukkan username Anda"
          type-input="text"
          :error-message="errorMessage.username"
        />
      </div>
      <div>
        <Input
          v-model="password"
          label="Password"
          placeholder="Masukkan password Anda"
          type-input="password"
          :error-message="errorMessage.password"
        />
      </div>
      <div class="mt-5 flex justify-start items-center hidden">
        <input type="checkbox" />
        <label class="ml-3 font-[Lato] text-[14px] text-[#424242]">
          Ingat saya
        </label>
      </div>
      <div class="mt-5 text-center">
        <Button variant="h-[38px] w-full" @click="loginHandle">Login</Button>
      </div>
      <div>
        <p class="font-[Lato] text-[14px] text-gray-700 mt-7 text-center">
          Belum memiliki akun?
          <router-link to="#" class="text-[#2196F3]"
            >Daftar sekarang</router-link
          >
        </p>
      </div>
      <Modal :show="isLoading" />
    </div>
  </div>
</template>
<script>
import Button from '@/components/Base/Button/PrimaryButton.vue'
import Input from '@/components/Login/Input.vue'
import Alert from '@/components/Base/Alert'
import Modal from '@/components/Base/Modal'
import { mapGetters } from 'vuex'

export default {
  components: {
    Button,
    Input,
    Alert,
    Modal,
  },
  data() {
    return {
      isShowModal: false,
      username: '',
      password: '',
      errorMessage: {
        username: '',
        password: '',
      },
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters(['getIsError', 'getErrorMessage']),
  },
  watch: {
    username() {
      this.errorMessage.username = this.validation(this.username, 'username')
    },
    password() {
      this.errorMessage.password = this.validation(this.password, 'password')
    },
  },
  methods: {
    close() {
      this.$store.dispatch('closeAlert', false)
    },
    validation(value, name) {
      let result = ''
      if (value.trim().length === 0) {
        result = `<span class="capitalize">${name}</span> wajib diisi`
      } else {
        const tagScript = /[<>]/g
        if (tagScript.test(value)) {
          result = `<span class='capitalize'>${name}</span> tidak mengandung tag script`
        }
        if (value.startsWith('http://') || value.startsWith('https://')) {
          result = `<span class="capitalize">${name}</span> tidak mengandung link url`
        }
      }

      return result
    },
    showModal() {
      this.isShowModal = true
    },
    closeModal() {
      this.isShowModal = !this.isShowModal
    },
    async loginHandle(event) {
      event.preventDefault()
      this.errorMessage.username = this.validation(this.username, 'username')
      this.errorMessage.password = this.validation(this.password, 'password')
      if (
        this.errorMessage.username == '' &&
        this.errorMessage.password == ''
      ) {
        this.isLoading = true
        try {
          const user = { username: this.username, password: this.password }
          await this.$store.dispatch('loginHandle', user)
          this.$router.push('/kandidat')
        } catch (error) {
          this.$store.dispatch('closeAlert', true)
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style scoped>
label {
  font-size: 15px;
  font-weight: 400;
  color: #424242;
  font-family: 'Lato';
}
input[type='text'],
input[type='password'] {
  background-color: #fdfdfd;
  height: 38px;
  border: 1px solid #9e9e9e;
  border-radius: 8px;
  width: 328px;
  padding: 8px;
  color: #232323;
}
input[type='text']:focus,
input[type='password']:focus {
  outline: none;
}
.icons {
  color: aqua;
  font-size: 16px;
}
</style>