import router from '.'
import { getToken } from '@/helpers/cookie'
import store from '@/store'

router.beforeEach(async (to, from, next) => {
  store.commit('setLoading', true)
  store.commit('setMenuActive', to.name)
  const token = getToken('token')
  if (token !== '') {
    if (to.name == 'login') {
      next('/kandidat')
    } else {
      await store.dispatch('getUserLoginHandle', token)
      next()
    }
  } else {
    if (to.name !== 'login') {
      next('/login')
    } else {
      next()
    }
  }
})
