<template>
  <button
    class="
      bg-[#069550]
      text-white
      rounded
      text-[14px]
      font-[Lato] font-semibold
    "
    :class="`${variant}`"
  >
    <slot name="icon-left" />
    <slot name="default" />
    <slot name="icon-right" />
  </button>
</template>
<script>
export default {
  name: 'PrimaryButton',
  props: {
    name: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: '',
    },
  },
}
</script>




