<template>
  <div id="optionPopup">
    <div
      class="
        flex
        justify-between
        w-full
        items-center
        h-[38px]
        border border-[#9E9E9E]
        rounded-lg
        pl-[8px]
        pr-[14px]
        mt-[6px]
        bg-[#FAFAFA]
        cursor-pointer
      "
      @click="showOptions"
    >
      <span class="font-[Lato] text-[14px] text-[#757575]"
        >{{ isReset ? title : valueFilter || title }}
      </span>
      <ChevronDownIcon class="w-[18px] h-[18px] text-green-600" />
    </div>
    <div
      :class="`absolute mt-5px p-[16px] w-[450px] min-h-fit rounded-lg bg-white shadow-lg z-10 ${
        !isShowOptions ? 'hidden' : ''
      }`"
    >
      <SearchInput
        variant-form="border border-[#757575] text-[16px] text-[#757575] h-[38px] w-full"
        variant-layout="hidden"
        placeholder="Cari Posisi"
      />
      <div class="options">
        <option
          v-for="option in listOptions"
          :key="option.id"
          :value="option.name"
          @click="optionChooseHandle(option)"
        >
          {{ option.name }}
        </option>
      </div>
    </div>
  </div>
</template>
<script>
import { ChevronDownIcon } from '@heroicons/vue/24/outline'
import SearchInput from '@/components/Base/SearchInput'

export default {
  name: 'DropdownWithSearch',
  components: {
    ChevronDownIcon,
    SearchInput,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    listOptions: {
      type: Array,
      default: new Array(),
    },
    isReset: {
      type: Boolean,
      default: false,
    },
    valueFilter: {
      type: String,
      default: '',
    },
    typeFilter: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isShowOptions: false,
      valueOptions: {},
    }
  },
  mounted() {
    document.addEventListener('click', this.closeDropdown)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeDropdown)
  },
  methods: {
    showOptions() {
      this.isShowOptions = !this.isShowOptions
    },
    optionChooseHandle(option) {
      this.$emit('submit', { type: this.typeFilter, option })
      this.isShowOptions = !this.isShowOptions
    },
    closeDropdown(event) {
      if (!this.$el.contains(event.target)) {
        this.isShowOptions = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
.options {
  overflow: auto;
  max-height: 200px;
  margin-top: 20px;
  option {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    margin-bottom: 5px;
    padding: 5px;
    cursor: pointer;
  }

  option:hover {
    background-color: #069550;
    color: white;
  }
}

.options::-webkit-scrollbar {
  width: 6px;
}

.options::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 25px;
}

.options::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 25px;
}
</style>